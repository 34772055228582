import { useCallback, useEffect, useState } from 'react';
import apiV1 from 'config/axios/v1.instance';
import { initDomainList } from 'api/initialStates';
import { IDomainListModel } from 'models/api/domain.model';
export const useFetchDomainList = () => {
  const [data, setData] = useState<IDomainListModel>(initDomainList);
  const [state, setState] = useState({ loading: true, error: null });
  const getDataServer = useCallback(() => {
    apiV1
      .get<IDomainListModel>('/domains')
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, []);
  useEffect(() => {
    getDataServer();
  }, [getDataServer]);
  return { data, ...state, refresh: getDataServer };
};
