import React, { useState } from 'react';
import { Table, Typography } from 'antd';
import { useHistory } from 'react-router-dom';
import { onDeleteTemplate } from 'helpers/deleteRecord';
import { columnsTemplate } from 'config/antd/table.config';
import { ActionsNew } from 'components/actionsForm/actionsForm';
import { useFetchTemplateList } from 'api/template/template.api';

const { Title, Text } = Typography;

const TemplateListPage = () => {
  const history = useHistory();
  const [page, setPage] = useState(1);
  const { data, loading, refresh } = useFetchTemplateList({ page, limit: 30 });

  const onClick = (payload: any) =>
    history.push(`/signatureTemplateCreate?id=${payload.id}`);
  const onDelete = (event: any, item: any) =>
    onDeleteTemplate(event, item, refresh);

  const createTemplate = () => history.push('/signatureTemplateCreate');
  const handleTableChange = (pagination: any) => setPage(pagination.current);

  return (
    <>
      <div
        className="animate__animated animate__fadeIn"
        style={{ minHeight: '75vh', position: 'relative' }}
      >
        <Title level={1} style={{ marginBlockEnd: 0 }}>
          Template List
        </Title>
        <ActionsNew onClick={createTemplate} text="Create Template" />

        <div className="mt-3 table-responsive">
          <p>
            <Text>Click in row for edit template</Text>
          </p>
          <Table
            loading={loading}
            dataSource={data.items}
            columns={columnsTemplate(onDelete)}
            onChange={handleTableChange}
            rowKey={(record) => record.id}
            onRow={(record: any) => ({ onClick: () => onClick(record) })}
            pagination={{
              showSizeChanger: false,
              pageSize: 30,
              total: data.total_items,
              showTotal: (total: number, range: [number, number]) =>
                `View ${range[0]} - ${range[1]} of ${
                  total === 0 ? `0` : `${total} items`
                }`,
              current: page,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default TemplateListPage;
