import CardPlans from 'components/cardPlans/cardPlans';
import useStateRedux from 'hooks/useStateRedux';

const Plans = ({ data }: IPlans) => {
  const { edition }: any = useStateRedux('auth');

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'column',
        padding: '16px',
        boxSizing: 'border-box',
        width: '80%',
        maxWidth: '1200px',
        margin: '0 auto',
      }}
    >
      <div
        style={{
          display: 'grid',
          gap: '10px',
          gridTemplateColumns: 'repeat(auto-fill, minmax(280px, 1fr))',
          width: '100%',
        }}
      >
        {data
          .filter(
            (i: any) =>
              i.id !== 'trial' &&
              !(['ZENT1M', 'ZENT12M'].includes(edition) && i.id === 'pro'),
          )
          .map((i: any) => (
            <CardPlans
              key={i.id}
              {...i}
              head={(props: any) => (
                <CardPlans.PricingInformation {...i} {...props} />
              )}
            />
          ))}
      </div>
    </div>
  );
};

interface IPlans {
  data: any;
  handleActive?: (id: any) => void;
}

export default Plans;
