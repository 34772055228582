import { message, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import useStateRedux from 'hooks/useStateRedux';
import { useEffect } from 'react';
import Loading from 'components/loading/loading';
import {
  handleEditFormProfile,
  handleResetForm,
} from 'redux/actions/forms.action';
import { ActionsSettings } from 'components/actionsForm/actionsForm';
import { useHistory } from 'react-router-dom';
import { updateProfileById } from 'api/person/person.api';
import { uiHandleLoading } from 'redux/actions/ui.action';
import GeneralFrom from './components/generalFrom';
import AdvanceFrom from './components/advanceFrom';
import { formProfile } from 'utils/formConstant';
import { useForm } from 'antd/lib/form/Form';

const { Title } = Typography;

const ProfilePage = (props: IProps) => {
  const [generalForm] = useForm();
  const [advanceForm] = useForm();

  const dispatch = useDispatch();
  const { forms, ui }: any = useStateRedux();

  const history = useHistory();
  const { profile } = forms;
  const {
    match: {
      params: { id },
    },
  } = props;

  function addTypeValue(array: any) {
    array.forEach(
      (obj: { hasOwnProperty: (arg0: string) => any; type: string }) => {
        if (!obj.hasOwnProperty('type')) {
          obj.type = 'work';
        }
      },
    );
    return array;
  }

  const onFinish = async () => {
    try {
      await generalForm.validateFields();
      await advanceForm.validateFields();

      const payload = {
        organizations: addTypeValue(profile.organizations),
        relations: profile.relations,
        emails: addTypeValue(profile.emails),
        websites: profile.websites,
        phones: profile.phones,
      };

      dispatch(uiHandleLoading());
      const success = await updateProfileById(profile.id, payload);
      if (!success) message.error('Failed to update profile');
      else {
        message.success('Update successful');
        history.push('/entity/person');
      }
      dispatch(uiHandleLoading());
    } catch (error) {
      message.error('Please fill in all required fields');
    }
  };

  useEffect(() => {
    dispatch(handleResetForm(formProfile));
    // (if) Launch edit form template
    // (else) Reset clean form
    !!id && dispatch(handleEditFormProfile(id));
  }, [dispatch, id]);

  if (ui.loading) return <Loading />;

  return (
    <div
      className="animate__animated animate__fadeIn"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      {/*Title name*/}
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        {profile.name.fullName}
      </Title>

      {/*Action Buttons*/}
      <div className="view-desktop">
        <ActionsSettings
          onClick={onFinish}
          onCancel={() => history.push('/entity/person')}
        />
      </div>

      {/*Form Row*/}
      <div>
        <GeneralFrom form={generalForm} />
        <AdvanceFrom form={advanceForm} />
      </div>
    </div>
  );
};

interface IProps {
  match: { params: { id: string | undefined } };
  history: any;
  location: any;
}

export default ProfilePage;
