import { Button, Dropdown, Menu, Tooltip } from 'antd';

const CustomDropdown = ({ title, items, clickAttr }: ICustomDropdownProps) => {
  const menu = (
    <Menu>
      {items.map((item: any) => (
        <Menu.Item key={item.id} onClick={() => clickAttr(item.insert)}>
          <Tooltip title={item.tooltip}>{item.title}</Tooltip>
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <Dropdown overlay={menu} placement="bottomLeft">
      <Button type="dashed">{title}</Button>
    </Dropdown>
  );
};

interface ICustomDropdownProps {
  title: string;
  items: any[];
  clickAttr: any;
}

export default CustomDropdown;
