import { CheckCircleOutlined, CloudOutlined } from '@ant-design/icons';
import { Card, Divider, Empty, List, Skeleton, Tag, Typography } from 'antd';
import { useFetchDomainList } from 'api/domain/domains.api';
export const DomainList = () => {
  const { data, loading, error } = useFetchDomainList();

  return (
    <Card
      title={
        <Typography.Title level={5} style={{ margin: 0 }}>
          Domains
        </Typography.Title>
      }
      style={{
        backgroundColor: '#f2f4f7',
        borderRadius: 8,
        boxShadow: '0 1px 3px rgba(0,0,0,0.08)',
      }}
      bodyStyle={{ padding: '16px 0' }}
    >
      <Divider style={{ margin: 0 }} />
      {error ? (
        <div style={{ padding: 16, textAlign: 'center' }}>
          <Empty description={error || 'Failed to fetch the information'} />
        </div>
      ) : loading ? (
        <div style={{ padding: 16 }}>
          <Skeleton active paragraph={{ rows: 3 }} />
        </div>
      ) : (
        <List
          dataSource={data.records}
          renderItem={(item) => (
            <List.Item
              style={{
                padding: '12px 16px',
                borderBottom: '1px solid #f0f0f0',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                flexWrap: 'wrap',
                gap: '8px',
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  flexWrap: 'wrap',
                }}
              >
                <CloudOutlined style={{ marginRight: 8, color: '#3ca898' }} />
                <Typography.Text
                  style={{
                    fontSize: 16,
                    color: '#2d3436',
                    fontWeight: 500,
                  }}
                >
                  {item.domain_name}
                </Typography.Text>
              </div>
              <Tag
                icon={<CheckCircleOutlined />}
                color={item.is_primary ? 'green' : 'gray'}
                style={{ marginTop: 4 }}
              >
                {item.is_primary ? 'Primary' : 'Secondary'}
              </Tag>
            </List.Item>
          )}
          locale={{
            emptyText: <Empty description="No domains registered" />,
          }}
        />
      )}
    </Card>
  );
};
export default DomainList;
