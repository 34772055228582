import React from 'react';
import { Avatar, Button } from 'antd';
import {
  ApartmentOutlined,
  CheckCircleFilled,
  CloseCircleOutlined,
  LeftCircleFilled,
} from '@ant-design/icons';
import './styles.scss';

const CardListPeople = (props: IProps) => {
  const { department, signature, suspended, onClick, footer, ...rest } = props;
  return (
    <div
      className="card__list-wrapper-dash"
      onClick={footer ? undefined : () => (!!onClick ? onClick(props) : {})}
    >
      <div className="card__list_dash">
        <HeaderAvatar {...rest} suspended={suspended} />
        <div className="card__content-flex">
          {!footer ? (
            <>
              <div className="card__details">
                <div className="enable">
                  <ApartmentOutlined /> {department}
                </div>
              </div>
              <div className="card__details">
                <div className={`enable ${signature ? 'text-success' : ''}`}>
                  {signature ? <CheckCircleFilled /> : <CloseCircleOutlined />}{' '}
                  SIGNATURE
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="card__details">
                <Button
                  type="text"
                  onClick={() => (!!onClick ? onClick(props) : {})}
                  icon={
                    <LeftCircleFilled className="administrator__table-nocheck" />
                  }
                />
              </div>
            </>
          )}
          <div className="card__details">
            <div className={`enable`}>
              {!!footer ? (
                footer
              ) : !suspended ? (
                <>
                  <CheckCircleFilled /> ENABLE
                </>
              ) : (
                <>
                  <CloseCircleOutlined /> DISABLE
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

// Head Avatar + Name + email
const HeaderAvatar = ({ name, email, primary_email, ...rest }: any) => {
  return (
    <div className="card__content-flex">
      <CustomAvatar name={name} {...rest} />
      <div className="card__title-head">
        <span className="name">{name}</span>
        <span className="email">{email || primary_email}</span>
      </div>
    </div>
  );
};

// Avatar component image
const CustomAvatar = ({
  name,
  suspended,
  image_uri,
}: {
  name: string;
  suspended: boolean;
  image_uri?: string;
}) => {
  return (
    <div className="card__custom-avatar">
      <Avatar
        src={
          image_uri ||
          `https://ui-avatars.com/api/?name=${name}&length=1&background=random`
        }
        srcSet={image_uri}
      />
      <AvatarState suspended={suspended} />
    </div>
  );
};

// state green or grey
const AvatarState = ({ suspended }: { suspended: boolean }) => {
  return (
    <div className="avatar_state">
      <div className="wrapper">
        <svg
          width="8"
          height="8"
          viewBox="0 0 8 8"
          className=" R90p3b vMbxHf H14eUd "
        >
          <circle
            cx="4px"
            cy="4px"
            r="3px"
            style={
              !suspended
                ? { fill: '#52C41A', stroke: '#52C41A', strokeWidth: '1px' }
                : { fill: 'transparent', stroke: '#ff5500', strokeWidth: '1px' }
            }
          />
        </svg>
      </div>
    </div>
  );
};

const DepartmentElement = (props: IProps) => {
  const { department, signature, footer } = props;
  return (
    <div className="card__content-flex">
      {!footer && (
        <>
          <div className="card__details">
            <div className="enable">
              <ApartmentOutlined /> {department}
            </div>
          </div>

          <div className="card__details">
            <div className={`enable ${signature ? 'text-success' : ''}`}>
              {signature ? <CheckCircleFilled /> : <CloseCircleOutlined />}{' '}
              SIGNATURE
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const SuspendedElement = (props: IProps) => {
  const { suspended, footer } = props;
  return (
    <div className="card__details enable text-left">
      {!!footer ? (
        footer
      ) : !suspended ? (
        <>
          <CheckCircleFilled /> ENABLE
        </>
      ) : (
        <>
          <CloseCircleOutlined /> DISABLE
        </>
      )}
    </div>
  );
};

interface IProps {
  id: any;
  name: string;
  department: string;
  email: string;
  signature: boolean;
  suspended: boolean;
  footer?: string | React.ReactNode;
  onClick?: (payload: IProps) => void;
}

export default HeaderAvatar;
export { HeaderAvatar, DepartmentElement, SuspendedElement, CardListPeople };
