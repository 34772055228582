import v1 from 'config/axios/v1.instance';
import { initSignatureList } from '../initialStates';
import { useCallback, useEffect, useState } from 'react';
import { ITemplateListModel } from 'models/api/template.model';
import { createTemplateData } from 'redux/initialState/form-initial-state';

// data cleaning, as api fails :'(
const formatTemplate = (data: any) => {
  let newData = {};
  Object.keys(data).forEach(
    (key) =>
      createTemplateData.hasOwnProperty(key) &&
      (newData = { ...newData, [key]: data[key] }),
  );
  return newData;
};

export const useFetchTemplateList = ({
  page = 1,
  limit,
}: {
  page: number;
  limit?: number;
}) => {
  // definition state
  const [data, setData] = useState<ITemplateListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    v1.get<ITemplateListModel>(`/templates?page=${page}&limit=${limit}`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        console.log(!!err?.response ? err?.response?.data : err?.message);
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page]);

  useEffect(() => {
    getDataServer();
  }, [page, getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const useFetchTemplateOrGalleryList = (
  page: number = 1,
  type: 'templates' | 'gallery' = 'templates',
) => {
  // definition state
  const [data, setData] = useState<ITemplateListModel>(initSignatureList);
  const [state, setState] = useState({ loading: true, error: null });

  // get data server api
  const getDataServer = useCallback(() => {
    setState((prev) => ({ ...prev, loading: true }));
    v1.get<ITemplateListModel>(`/${type}?page=${page}`)
      .then(({ data }) => {
        setData(data);
        setState((prev) => ({ ...prev, loading: false }));
      })
      .catch((err) => {
        console.log(!!err?.response ? err?.response?.data : err?.message);
        setState((state) => ({
          ...state,
          loading: false,
          error: !!err?.response ? err?.response?.data : err?.message,
        }));
      });
  }, [page, type]);

  useEffect(() => {
    getDataServer();
  }, [page, getDataServer]);

  // return state
  return { data, ...state, refresh: getDataServer };
};

export const getByIdTemplate = (id: number) => {
  return v1
    .get(`/templates/${id}`)
    .then(({ data }) => {
      return { data, err: false };
    })
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

export const createTemplate = (beforeBody: any) => {
  const body = formatTemplate(beforeBody);

  return v1
    .post('/templates', body)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

export const editTemplate = (beforeBody: any, id: number) => {
  const body = formatTemplate(beforeBody);
  return v1
    .put(`/templates/${id}`, body)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};

export const deleteTemplate = (id: number) => {
  return v1
    .delete(`/templates/${id}`)
    .then(({ data }) => ({ data, err: false }))
    .catch((err) => ({
      data: null,
      err: !!err?.response ? err?.response?.data : err?.message,
    }));
};
