import { Button, message } from 'antd';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { memo, useState } from 'react';
import useStateRedux from 'hooks/useStateRedux';
import ReuseModal from 'components/modal/reuseModal';
import { applySignature } from 'api/signature/signature.api';
import RenderTemplate from 'components/renderTemplate/renderTemplate';
import {
  handleFormSignature,
  handleFormTemplate,
} from 'redux/actions/forms.action';
import {
  CheckCircleFilled,
  CheckOutlined,
  CloseCircleFilled,
  CloseOutlined,
  EyeFilled,
  SaveFilled,
} from '@ant-design/icons';

interface IActionForm {
  type?: 'template' | 'signature';
  form?: string;
  setIsModalVisible?: any;
}

export const Actions = ({
  type = 'signature',
  form = '',
  setIsModalVisible,
}: IActionForm) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { ui, forms }: any = useStateRedux();
  const { edition }: any = useStateRedux('auth');

  const [visible, setVisible] = useState(false);

  const onCancel = async () => {
    await dispatch(
      type === 'signature'
        ? handleFormSignature({ save: true })
        : handleFormTemplate({ save: true }),
    );
    if (type === 'signature') return history.push('/signatureList');
    return history.push('/signatureTemplateList');
  };

  const onPreview = () => {
    if (!forms[form].body)
      return message.warning('Please create a template in the editor');
    setVisible(true);
  };

  const _applySignature = async () => {
    message.loading({ content: 'Loading...', key: 'loading', duration: 0 });
    const { err } = await applySignature(forms[form].id, forms[form]);
    message.destroy('loading');
    if (err) return message.error('Error, signature not applied');
    return message.success('Signature applied');
  };

  const onCancelModal = () => setVisible(false);

  return (
    <div className="btn-actions-header">
      <ReuseModal
        destroyOnClose
        cancelText="Ok"
        visible={visible}
        onCancel={onCancelModal}
        title={`Preview ${form}`}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <RenderTemplate body={forms[form].body} />
      </ReuseModal>

      <Button
        onClick={onCancel}
        type="default"
        icon={<CloseCircleFilled />}
        className="mr-1"
      >
        Cancel
      </Button>
      <Button
        onClick={onPreview}
        type="default"
        className="mr-1 btn-secondary-text"
        icon={<EyeFilled />}
      >
        Preview
      </Button>
      {forms[form].edit.editing && form === 'signature' && (
        <Button
          danger
          onClick={
            forms[form].can_edit === 0 || edition === 'EXPIRED'
              ? () => setIsModalVisible(true)
              : _applySignature
          }
          type="default"
          className="mr-1 btn-purple-text"
          icon={<CheckCircleFilled />}
        >
          Apply
        </Button>
      )}
      <Button
        danger
        form={form}
        type="default"
        htmlType="submit"
        icon={<SaveFilled />}
        loading={!!ui.optionalLoading}
      >
        {forms[form].edit.editing ? 'Update' : 'Save'}
      </Button>
    </div>
  );
};

export const ActionsNew = memo(({ text = 'New', onClick }: IActionNew) => {
  const handleClick = () => (!!onClick ? onClick() : null);

  return (
    <div className="btn-actions-header">
      <Button type="primary" onClick={handleClick} icon={<CheckOutlined />}>
        {text}
      </Button>
    </div>
  );
});

export const ActionsSettings = memo(
  ({
    okText = 'Save',
    cancelText = 'Close',
    onClick,
    onCancel,
  }: IActionSettings) => {
    const handleClose = () => (!!onCancel ? onCancel() : null);
    const handleClick = () => (!!onClick ? onClick() : null);

    return (
      <div className="btn-actions-header">
        <Button
          type="default"
          onClick={handleClose}
          className="mr-1"
          icon={<CloseOutlined />}
        >
          {cancelText}
        </Button>
        <Button type="primary" onClick={handleClick} icon={<CheckOutlined />}>
          {okText}
        </Button>
      </div>
    );
  },
);

interface IActionNew {
  text?: string;
  onClick?: () => void;
}

interface IActionSettings {
  okText?: string;
  cancelText?: string;
  onClick?: () => void;
  onCancel?: () => void;
}
