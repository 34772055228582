import React from 'react';
import AdvanceConfig from './advanceConfig';
import InformationForm from './informationForm';
import ChoosePeopleForm from './choosePeopleForm';

const CurrentCreateSignatureView = ({ active, isEditing }: IActionProps) => {
  switch (active) {
    case 'information':
      return <InformationForm isEditing={isEditing} />;
    case 'choosePeople':
      return <ChoosePeopleForm />;
    case 'advanceConfig':
      return <AdvanceConfig />;

    default:
      return <InformationForm isEditing={isEditing} />;
  }
};

interface IActionProps {
  active: 'information' | 'choosePeople' | 'advanceConfig' | string;
  isEditing: boolean;
}

export default CurrentCreateSignatureView;
