import { ISignatureListModel } from 'models/api/signature.model';
import { ISuit } from 'models/api/auth.model';
import { IDomainListModel } from 'models/api/domain.model';

export const initSignatureList: ISignatureListModel = {
  items: [],
  total_items: 0,
  pages: 1,
  current_page: 1,
};

export const initDomainList: IDomainListModel = {
  records: [],
  _metadata: {
    page: 0,
    per_page: 0,
    total_count: 0,
    total_page: 0,
  },
};

export const initialSuitModel: ISuit = {
  admin_full_name: '',
  customer_id: '',
  admin_id: '',
  admin_email: '',
  licenses: 0,
  domains: [],
  number_of_employees: '',
};
