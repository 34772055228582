import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, List, Switch, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { setGoogleAuthenticate } from '../../redux/actions/auth.action';
import { CheckCircleOutlined } from '@ant-design/icons';
import useStateRedux from 'hooks/useStateRedux';

const { Title } = Typography;

interface ICardPlansProps {
  title: string;
  pricing: {
    monthly: {
      integer: number;
      decimal: number;
      code: string;
    };
    annually: {
      integer: number;
      decimal: number;
      code: string;
    };
  };
  href: string;
  head: any;
  okText?: string;
  footer?: boolean;
  onClick?: (title: string, currentMembership?: string) => void;
  buttonClick?: (title: string, currentMembership: string, e: any) => void;
  children?: React.ReactNode;
}

interface IPricingInformationProps {
  pricing?: any;
  head?: string;
  title: string;
  setCurrentTime: any;
  currentTime: string;
  advantage: string[];
  onClick?: (title: string, currentMembership?: string) => void;
}

interface ICardActionButtonProps {
  okText: string;
  href: string;
  plan: string;
  current: string;
  buttonClick?: (title: string, currentMembership: string, e: any) => void;
}

const CardPlans = (props: ICardPlansProps) => {
  const {
    title,
    pricing,
    head: HeadCard,
    children,
    okText,
    href,
    footer = true,
    onClick,
    buttonClick,
  } = props;

  const { edition }: any = useStateRedux('auth');

  const [currentTime, setCurrentTime] = useState('annually');

  const isCurrentPlan =
    edition === pricing.monthly.code || edition === pricing.annually.code;

  return (
    <span style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
      <Card
        onClick={() => {
          !!onClick && onClick(title, currentTime);
        }}
        style={{
          marginBottom: '32px',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          maxWidth: '600px',
          width: '100%',
        }}
      >
        <div
          style={{
            flexGrow: 0,
            textAlign: 'left',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Title level={3} style={{ margin: 0 }}>
            {title}
          </Title>

          {isCurrentPlan && (
            <span
              style={{
                backgroundColor: '#E6F4EA',
                color: '#207245',
                padding: '4px 8px',
                borderRadius: '8px',
                fontSize: '10px',
                fontWeight: 'bold',
                whiteSpace: 'nowrap',
              }}
            >
              Current Plan
            </span>
          )}
        </div>

        <div style={{ flexGrow: 1 }}>
          <HeadCard
            title={title}
            onClick={onClick}
            currentTime={currentTime}
            setCurrentTime={setCurrentTime}
          />
          {children}
        </div>

        {!isCurrentPlan && footer && (
          <div style={{ flexGrow: 0, marginTop: 'auto' }}>
            <CardActionButton
              href={href}
              plan={title}
              current={currentTime}
              okText={okText || 'ok'}
              buttonClick={buttonClick}
            />
          </div>
        )}
      </Card>
    </span>
  );
};

CardPlans.PricingInformation = ({
  pricing,
  head,
  onClick,
  title,
  setCurrentTime,
  currentTime,
  advantage,
}: IPricingInformationProps) => {
  const dispatch = useDispatch();

  const handleSwitch = (checked: boolean, e: Event) => {
    e.stopPropagation();
    const currentMembership = checked ? 'annually' : 'monthly';
    setCurrentTime(currentMembership);
    {
      !!onClick
        ? onClick(title, currentMembership)
        : dispatch(setGoogleAuthenticate({ currentMembership }));
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
      }}
    >
      {!!pricing[currentTime].integer || !!pricing[currentTime].decimal ? (
        <>
          <Title level={2} style={{ textAlign: 'left' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <span>{pricing[currentTime].integer}</span>
                <span style={{ marginLeft: '4px' }}>
                  .{pricing[currentTime].decimal}
                </span>
                <span style={{ marginLeft: '4px' }}>US$</span>
              </div>
              <span style={{ fontSize: '10px', color: '#8c8c8c' }}>
                member / {currentTime === 'annually' ? 'annually' : 'month'}
              </span>
            </div>
          </Title>

          <div
            style={{
              borderBottom: '1px solid #e8e8e8',
            }}
          />

          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              zIndex: 10000,
              textAlign: 'center',
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <label style={{ fontSize: '12px', fontWeight: 500 }}>
              Monthly
              <Switch
                onChange={handleSwitch}
                checked={currentTime === 'annually'}
                defaultChecked
                style={{ margin: '0 8px' }}
              />
              Annually
            </label>
          </div>

          <div>
            <Title level={5} style={{ marginBottom: '6px', textAlign: 'left' }}>
              <strong>{title}</strong> plan includes:
            </Title>
            <List
              dataSource={advantage}
              renderItem={(item) => (
                <List.Item style={{ textAlign: 'left' }}>
                  <CheckCircleOutlined
                    style={{ color: '#52c41a', marginRight: '8px' }}
                  />
                  {item}
                </List.Item>
              )}
            />
          </div>
        </>
      ) : (
        <>
          <Title
            level={5}
            style={{ fontSize: '10px', color: '#8c8c8c', textAlign: 'left' }}
          >
            {head}
          </Title>

          <div
            style={{
              marginTop: '10px',
              marginBottom: '10px',
              borderBottom: '1px solid #e8e8e8',
            }}
          />

          <div style={{ textAlign: 'left' }}>
            <Title level={5} style={{ marginBottom: '8px', textAlign: 'left' }}>
              <strong>{title}</strong> plan includes:
            </Title>
            <List
              dataSource={advantage}
              renderItem={(item) => (
                <List.Item style={{ textAlign: 'left' }}>
                  <CheckCircleOutlined
                    style={{ color: '#52c41a', marginRight: '8px' }}
                  />
                  {item}
                </List.Item>
              )}
            />
          </div>
        </>
      )}
    </div>
  );
};

const CardActionButton = ({
  okText,
  href,
  plan,
  current,
  buttonClick,
}: ICardActionButtonProps) => {
  const dispatch = useDispatch();
  const onClick = (e: any) => {
    if (!!buttonClick) return buttonClick(plan, current, e);
    dispatch(setGoogleAuthenticate({ plan, currentMembership: current }));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Link
        to={{ pathname: href }}
        onClick={onClick}
        target="_blank"
        style={{
          display: 'inline-block',
          width: '100%',
          textAlign: 'center',
        }}
      >
        <Button type="primary" size="large">
          {okText}
        </Button>
      </Link>
    </div>
  );
};

export default CardPlans;
