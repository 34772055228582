/* eslint-disable no-template-curly-in-string */

export const dataPricingSimplify: any = [
  {
    id: 'trial',
    title: 'Trial',
    href: `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`,
    head: '15 days trial',
    okText: 'Get Free Trial',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 0,
        code: 'TRIAL',
      },
      annually: {
        integer: 0,
        decimal: 0,
        code: 'TRIAL',
      },
    },
    image: '/Layout.svg',
    advantage: [
      'Signature Management',
      '10 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'pro',
    title: 'Pro',
    href: `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`,
    okText: 'Buy Now!',
    isFeature: true,
    pricing: {
      monthly: {
        integer: 0,
        decimal: 55,
        code: 'ZPRO1M',
      },
      annually: {
        integer: 5,
        decimal: 50,
        code: 'ZPRO12M',
      },
    },
    advantage: [
      'Signature Management',
      '10 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'enterprise',
    title: 'Enterprise',
    okText: 'Buy Now!',
    href: `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`,
    pricing: {
      monthly: {
        integer: 0,
        decimal: 70,
        code: 'ZENT1M',
      },
      annually: {
        integer: 7,
        decimal: 20,
        code: 'ZENT12M',
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
  {
    id: 'edu',
    title: 'Education',
    head: 'Contact Us',
    image: '/Typography.svg',
    okText: 'Contact us',
    href: 'https://support.zumpul.com/portal/en/newticket',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 0,
        code: 'EDU',
      },
      annually: {
        integer: 0,
        decimal: 0,
        code: 'EDU',
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Support & Training 24/7',
    ],
  },
];

export const dataPricing = [
  {
    id: 'pro',
    title: 'Pro',
    href: '',
    okText: 'Buy Now!',
    active: true,
    isFeature: true,
    pricing: {
      monthly: {
        integer: 0,
        decimal: 55,
      },
      annually: {
        integer: 5,
        decimal: 50,
      },
    },
    advantage: [
      'Signature Management',
      '10 Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Google Profile Auto Sync',
      'Support & Training 24/7',
      'Implementation consulting',
      'User Deprovisioning (Roadmap)',
      // 'Shared Contacts (Roadmap)',
    ],
  },
  {
    id: 'enterprise',
    title: 'Enterprise',
    okText: 'Buy Now!',
    active: false,
    href: '',
    pricing: {
      monthly: {
        integer: 0,
        decimal: 70,
      },
      annually: {
        integer: 7,
        decimal: 20,
      },
    },
    advantage: [
      'Signature Management',
      'Unlimited Signature templates',
      'Automatic Sync and Execution',
      'User Management & Directory',
      'Google Profile Auto Sync',
      'Support & Training 24/7',
      'Implementation consulting',
      'User Deprovisioning (Roadmap)',
      // 'Shared Contacts (Roadmap)',
    ],
  },
];

export const personalFields = [
  {
    id: 'firstName',
    tooltip: 'Insert First Name in the editor',
    title: 'First Name',
    insert: '${firstName}',
  },
  {
    id: 'lastName',
    tooltip: 'Insert Last Name in the editor',
    title: 'Last Name',
    insert: '${lastName}',
  },
  {
    id: 'primaryEmail',
    tooltip: 'Insert Email in the editor',
    title: 'Email',
    insert: '${primaryEmail}',
  },
  {
    id: 'title',
    tooltip: 'Insert Title in the editor',
    title: 'Title',
    insert: '${title}',
  },
  {
    id: 'manager',
    tooltip: 'Insert Manager in the editor',
    title: 'Manager',
    insert: '${manager}',
  },
  {
    id: 'department',
    tooltip: 'Insert Department in the editor',
    title: 'Department',
    insert: '${department}',
  },
  {
    id: 'company',
    tooltip: 'Insert Company Name in the editor',
    title: 'Company',
    insert: '${company}',
  },
  {
    id: 'workAddress',
    tooltip: 'Insert Work Address in the editor',
    title: 'Work Address',
    insert: '${workAddress}',
  },
];

export const contactFields = [
  {
    id: 'workPhone',
    tooltip: 'Insert Work Phone in the editor',
    title: 'Work Phone',
    insert: '${workPhone}',
  },
  {
    id: 'mobilePhone',
    tooltip: 'Insert Mobile Phone in the editor',
    title: 'Mobile Phone',
    insert: '${mobilePhone}',
  },
  {
    id: 'homePhone',
    tooltip: 'Insert Home Phone in the editor',
    title: 'Home Phone',
    insert: '${homePhone}',
  },
  {
    id: 'homeEmail',
    tooltip: 'Insert Home Email in the editor',
    title: 'Home Email',
    insert: '${homeEmail}',
  },
  {
    id: 'otherEmail',
    tooltip: 'Insert Other Email in the editor',
    title: 'Other Email',
    insert: '${otherEmail}',
  },
  {
    id: 'workEmail',
    tooltip: 'Insert Work Email in the editor',
    title: 'Work Email',
    insert: '${workEmail}',
  },
  // {
  //   id: 'otherAddress',
  //   tooltip: 'Insert Other Address in the editor',
  //   title: 'Other Address',
  //   insert: '${otherAddress}',
  // },
  {
    id: 'faxNumber',
    tooltip: 'Insert Fax Number in the editor',
    title: 'Fax Number',
    insert: '${faxNumber}',
  },
];

export const dataFrequencySignature = [
  {
    key: 'annual',
    label: 'Annual',
    value: 6,
  },
  {
    key: 'weekly',
    label: 'Weekly',
    value: 2,
  },
  {
    key: 'monthly',
    label: 'Monthly',
    value: 4,
  },
  {
    key: 'quarterly',
    label: 'Quarterly',
    value: 5,
  },
  {
    key: 'daily',
    label: 'Daily',
    value: 10,
  },
];

export const weekDays = [
  { value: '1', label: 'Monday' },
  { value: '2', label: 'Tuesday' },
  { value: '3', label: 'Wednesday' },
  { value: '4', label: 'Thursday' },
  { value: '5', label: 'Friday' },
  { value: '6', label: 'Saturday' },
  { value: '7', label: 'Sunday' },
];

export const IMKeys = [
  { value: 'aim', label: 'AIM' },
  { value: 'gtalk', label: 'Google Talk' },
  { value: 'icq', label: 'ICQ' },
  { value: 'jabber', label: 'Jabber' },
  { value: 'msn', label: 'MSN' },
  { value: 'net_meeting', label: 'Net Meeting' },
  { value: 'qq', label: 'QQ' },
  { value: 'skype', label: 'Skype' },
  { value: 'yahoo', label: 'Yahoo' },
];

export const TypeEmailKeys = [
  { value: 'work', label: 'Work' },
  { value: 'home', label: 'Home' },
  { value: 'custom', label: 'Custom' },
  { value: 'other', label: 'Other' },
];

export const TypeWebsiteKeys = [
  { value: 'app_install_page', label: 'App Install Page' },
  { value: 'blog', label: 'Blog' },
  { value: 'custom', label: 'Custom' },
  { value: 'ftp', label: 'FTP' },
  { value: 'home', label: 'Home' },
  { value: 'home_page', label: 'Home Page' },
  { value: 'profile', label: 'Profile' },
  { value: 'reservations', label: 'Reservations' },
  { value: 'resume', label: 'Resume' },
  { value: 'work', label: 'Work' },
  { value: 'other', label: 'Other' },
];

export const TypePhoneKeys = [
  // { value: 'assistant', label: 'Assistant' },
  // { value: 'callback', label: 'Callback' },
  // { value: 'car', label: 'Car' },
  // { value: 'company_main', label: 'Company Main' },
  // { value: 'custom', label: 'Custom' },
  // { value: 'grand_central', label: 'Grand Central' },
  { value: 'home', label: 'Home' },
  // { value: 'home_fax', label: 'Home Fax' },
  // { value: 'isdn', label: 'Isdn' },
  // { value: 'main', label: 'Main' },
  { value: 'mobile', label: 'Mobile' },
  // { value: 'other', label: 'Other' },
  // { value: 'other_fax', label: 'Other_fax' },
  // { value: 'pager', label: 'Pager' },
  // { value: 'radio', label: 'Radio' },
  // { value: 'telex', label: 'Telex' },
  // { value: 'tty_tdd', label: 'TTY_TDD' },
  { value: 'work', label: 'Work' },
  { value: 'work_fax', label: 'Work Fax' },
  // { value: 'work_mobile', label: 'Work Mobile' },
  // { value: 'work_pager', label: 'Work Pager' },
];
