import { useMemo } from 'react';
import moment, { MomentInput } from 'moment';
import UpgradeSub from './upgradeSub';
import { format } from 'helpers/momentConfig';
import useStateRedux from 'hooks/useStateRedux';
import { Button, Descriptions, Tag, Typography } from 'antd';
import { DollarOutlined, ExclamationCircleOutlined } from '@ant-design/icons';

const Subscription = () => {
  const { licenses, edition, ...rest }: any = useStateRedux('auth');

  const avaliableLicences = useMemo(() => licenses - rest.mailBox, [rest]);

  const expirationDate = useMemo((): MomentInput => {
    if (edition == 'EXPIRED') return rest.purchase_date;
    if (edition == 'TRIAL') return moment(rest.created_at).add(14);
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  const nextChargeDate = useMemo((): MomentInput => {
    if (['EXPIRED', 'TRIAL'].includes(edition)) return null;
    if (rest.purchase_date) {
      const match = edition.match(/\d+/);
      const monthsToAdd = parseInt(match[0], 10);
      return moment(rest.purchase_date)
        .add(monthsToAdd, 'months')
        .format('YYYY-MM-DD');
    }
    return null;
  }, [rest]);

  return (
    <div>
      <div
        className="card__list-wrapper card__create-form"
        style={{ backgroundColor: '#fff' }}
      >
        <Descriptions
          title={
            <span
              style={{
                fontSize: '20px',
                fontWeight: 600,
              }}
            >
              Subscription Information
            </span>
          }
          column={2}
          bordered
          size="middle"
          labelStyle={{
            fontWeight: 600,
            color: '#5a5a5a',
            width: '180px',
            backgroundColor: '#fafafa',
          }}
          contentStyle={{
            color: '#222',
            fontWeight: 500,
          }}
        >
          <Descriptions.Item label="Current Plan">
            <Tag color="blue" style={{ fontSize: '14px' }}>
              {edition}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Payment Method">
            {rest.payment_method || 'N/A'}
          </Descriptions.Item>
          <Descriptions.Item label="Expiration Date">
            <span>{moment(expirationDate).format(format)}</span>
          </Descriptions.Item>
          <Descriptions.Item label="Next Charge">
            {nextChargeDate ? (
              <span style={{ color: '#1890ff' }}>
                {moment(nextChargeDate).format(format)}
              </span>
            ) : (
              'N/A'
            )}
          </Descriptions.Item>
          <Descriptions.Item label="Licenses">
            {licenses}{' '}
            <Tag
              color={avaliableLicences >= 0 ? '#52c41a' : '#ff0000'}
              style={{ fontSize: '14px' }}
            >
              {avaliableLicences >= 0
                ? `Available: ${avaliableLicences}`
                : `You need to pay ${Math.abs(avaliableLicences)}`}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Mail Boxes">
            {rest.mailBox}
          </Descriptions.Item>
        </Descriptions>

        {edition !== 'TRIAL' && avaliableLicences < 0 && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              gap: '16px',
              marginTop: '20px',
              padding: '16px',
              backgroundColor: '#fff2f0',
              border: '1px solid #ffccc7',
              borderRadius: '6px',
            }}
          >
            <Typography.Text
              style={{
                textAlign: 'center',
                color: '#ff4d4f',
                fontSize: '15px',
              }}
            >
              <ExclamationCircleOutlined style={{ marginRight: '8px' }} />
              <b>Note:</b> You need to pay licenses for all collaborators from
              your Google Workspace(G Suite) Account
            </Typography.Text>
            <Button
              type="primary"
              danger
              size="large"
              icon={<DollarOutlined />}
              onClick={() => {
                window.location.href = `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`;
              }}
              style={{
                fontWeight: 600,
                width: '200px',
              }}
            >
              Pay Now
            </Button>
          </div>
        )}
      </div>
      {['TRIAL', 'EXPIRED'].includes(edition) || avaliableLicences < 1 ? (
        <UpgradeSub />
      ) : (
        <></>
      )}
    </div>
  );
};

export default Subscription;
