export const getCurrentMenuQuery = (path: string) => {
  if (path === '/') return { selectedKeys: ['dashboard'] };
  else if (path === '/signatureList' || path === '/signatureCreate')
    return {
      openKeys: ['signature'],
      selectedKeys: ['signature-list'],
    };
  else if (
    path === '/signatureTemplateList' ||
    path === '/signatureTemplateCreate'
  )
    return {
      openKeys: ['signature'],
      selectedKeys: ['signature-template'],
    };
  else if (path === '/entity/person' || path.includes('/profile'))
    return {
      openKeys: ['people'],
      selectedKeys: ['people-list'],
    };
  // else if (path === '/out-office/create' || path.includes('/out-office'))
  //   return {
  //     openKeys: ['people'],
  //     selectedKeys: ['out-office'],
  //   };
  // else if (path === '/sharedContactsList')
  //   return {
  //     openKeys: ['people'],
  //     selectedKeys: ['people-shared-contact'],
  //   };
  else if (path === '/setting')
    return {
      openKeys: ['setting'],
      selectedKeys: ['setting-general'],
    };
  else if (path === '/administrators')
    return {
      openKeys: ['setting'],
      selectedKeys: ['setting-administrators'],
    };
  else if (path === '/accountProfile')
    return {
      openKeys: ['setting'],
      selectedKeys: ['setting-profile'],
    };
  else return { openKeys: [], selectedKeys: [] };
};

// export const preventForm = (path: string) =>
//   path === '/signatureCreate' || path === '/signatureTemplateCreate';
