import { TypeEmailKeys, TypePhoneKeys, TypeWebsiteKeys } from 'utils/utils';
import React, { useEffect } from 'react';
import { FormInstance } from 'antd/es/form/Form';
import useStateRedux from 'hooks/useStateRedux';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row, Select, Space, Typography } from 'antd';
import { handleFormProfile } from 'redux/actions/forms.action';
import { useDispatch } from 'react-redux';

const { Title } = Typography;

const AdvanceFrom = ({ form }: IProps) => {
  const dispatch = useDispatch();
  const { profile }: any = useStateRedux('forms');
  const formChange = (value: any, changedValues: any) => {
    dispatch(
      handleFormProfile({
        emails: changedValues.emails,
        phones: changedValues.phones,
        websites: changedValues.websites,
      }),
    );
  };
  useEffect(() => {
    const newProfile = { ...profile };
    newProfile.emails = newProfile.emails
      .map((i: any) => {
        !i.type && !i.primary && (i.type = 'work');
        return i;
      })
      .sort((a: any, b: any) => (b.primary ? 1 : -1));
    form.setFieldsValue(newProfile);
  }, []);

  return (
    <div>
      <Form
        form={form}
        className="mt-3"
        layout="vertical"
        onValuesChange={formChange}
      >
        <Row gutter={[20, 20]}>
          <Col sm={24} md={12}>
            <Title level={3}>USER INFORMATION</Title>
            <Form.Item name={['name', 'givenName']} label="First Name">
              <Input size="large" disabled />
            </Form.Item>
            <Form.Item name={['name', 'familyName']} label="Last Name">
              <Input size="large" disabled />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Title level={3}>LINKS</Title>

            <Form.List name="websites">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.value !== curValues.value ||
                          prevValues.type !== curValues.type
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Type"
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[
                              {
                                required: true,
                                message: 'Type is required',
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 130 }}
                              placeholder="Select option"
                            >
                              {TypeWebsiteKeys.map((item: any) => (
                                <Select.Option
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Url website"
                        name={[field.name, 'value']}
                        fieldKey={[field.fieldKey, 'value']}
                        rules={[{ required: true, message: 'URL is required' }]}
                      >
                        <Input placeholder="https://example.com" />
                      </Form.Item>

                      <MinusCircleOutlined
                        className="text-danger"
                        onClick={() => remove(field.name)}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      size="large"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Website
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col sm={24} md={12}>
            <Title level={3}>EMAILS</Title>

            <Form.List name="emails">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.type !== curValues.type ||
                          prevValues.address !== curValues.address
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Type"
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[
                              {
                                required: field.fieldKey !== 0 ? true : false,
                                message: 'Type is required',
                              },
                            ]}
                          >
                            {field.fieldKey === 0 ? (
                              <Input
                                style={{ width: 130 }}
                                disabled
                                placeholder="Primary"
                              />
                            ) : (
                              <Select
                                style={{ width: 130 }}
                                placeholder="Select option"
                              >
                                {TypeEmailKeys.map((item: any) => (
                                  <Select.Option
                                    key={item.value}
                                    value={item.value}
                                  >
                                    {item.label}
                                  </Select.Option>
                                ))}
                              </Select>
                            )}
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Email Address"
                        name={[field.name, 'address']}
                        fieldKey={[field.fieldKey, 'address']}
                        rules={[
                          {
                            required: true,
                            message: 'Email Address is required',
                          },
                        ]}
                      >
                        {field.fieldKey === 0 ? <Input disabled /> : <Input />}
                      </Form.Item>

                      {field.fieldKey !== 0 && (
                        <MinusCircleOutlined
                          className="text-danger"
                          onClick={() => remove(field.name)}
                        />
                      )}
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      size="large"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Email
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
          <Col sm={24} md={12}>
            <Title level={3}>NUMBERS PHONE</Title>

            <Form.List name="phones">
              {(fields, { add, remove }) => (
                <>
                  {fields.map((field) => (
                    <Space key={field.key} align="baseline">
                      <Form.Item
                        noStyle
                        shouldUpdate={(prevValues, curValues) =>
                          prevValues.value !== curValues.value ||
                          prevValues.type !== curValues.type
                        }
                      >
                        {() => (
                          <Form.Item
                            {...field}
                            label="Type"
                            name={[field.name, 'type']}
                            fieldKey={[field.fieldKey, 'type']}
                            rules={[
                              {
                                required: true,
                                message: 'Type is required',
                              },
                            ]}
                          >
                            <Select
                              style={{ width: 130 }}
                              placeholder="Select option"
                            >
                              {TypePhoneKeys.map((item: any) => (
                                <Select.Option
                                  key={item.value}
                                  value={item.value}
                                >
                                  {item.label}
                                </Select.Option>
                              ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Form.Item>
                      <Form.Item
                        {...field}
                        label="Phone Number"
                        name={[field.name, 'value']}
                        fieldKey={[field.fieldKey, 'value']}
                        rules={[
                          {
                            required: true,
                            message: 'Phone Number is required',
                          },
                        ]}
                      >
                        <Input placeholder="+503 74125678" />
                      </Form.Item>

                      <MinusCircleOutlined
                        className="text-danger"
                        onClick={() => remove(field.name)}
                      />
                    </Space>
                  ))}

                  <Form.Item>
                    <Button
                      size="large"
                      type="dashed"
                      onClick={() => add()}
                      block
                      icon={<PlusOutlined />}
                    >
                      Add Phone
                    </Button>
                  </Form.Item>
                </>
              )}
            </Form.List>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

interface IProps {
  form: FormInstance<any>;
}

export default AdvanceFrom;
