import { message } from 'antd';
import { type } from '../types/index.type';
import { getByIdProfile } from 'api/person/person.api';
import { ITemplateModel } from 'models/api/template.model';
import { ISignatureModel } from 'models/api/signature.model';
import { uiHandleLoading, uiHandleOptionalLoading } from './ui.action';
import {
  createTemplate,
  editTemplate,
  getByIdTemplate,
} from 'api/template/template.api';
import {
  createSignature,
  editSignature,
  getByIdEntities,
  getByIdSignature,
} from 'api/signature/signature.api';

// Form Template
export const createTemplateAction = (
  payload: ITemplateModel,
  history: any,
  form: string,
) => async (dispatch: any) => {
  if (!payload.body)
    return message.error('Please create a template in the editor');
  dispatch(uiHandleOptionalLoading(true));
  const { err }: any = await createTemplate(payload);
  if (err) {
    dispatch(uiHandleOptionalLoading(false));
    return message.error(`${err?.message || err?.errors || err?.error}`);
  } else {
    message.success('Created Template!');
    dispatch(handleResetForm(form));
    return history.push('/signatureTemplateList');
  }
};

export const updateTemplateAction = (
  payload: ITemplateModel,
  history: any,
  form: string,
) => async (dispatch: any) => {
  if (!payload.body)
    return message.error('Please create a template in the editor');
  dispatch(uiHandleOptionalLoading(true));
  const { err }: any = await editTemplate(payload, payload.id);
  if (err) {
    dispatch(uiHandleOptionalLoading(false));
    return message.error(`${err?.message || err?.errors || err?.error}`);
  } else {
    message.success('Updated Template!');
    dispatch(handleResetForm(form));
    return history.push('/signatureTemplateList');
  }
};

export const handleFormTemplate = (payload: any) => (dispatch: any) =>
  dispatch({
    type: type.formHandleTemplate,
    payload,
  });

export const handleResetForm = (payload: string) => (dispatch: any) => {
  dispatch(uiHandleOptionalLoading(false));
  dispatch({ type: type.formClean, payload });
};

export const handleEditFormTemplate = (payload: any) => async (
  dispatch: any,
) => {
  dispatch(uiHandleLoading());
  const edit = { id: payload.id, editing: true };
  const { data, err } = await getByIdTemplate(payload.id);
  if (err) message.error(`${err?.message || err?.errors || err?.error}`);
  else dispatch(handleFormTemplate({ ...data, edit }));
  dispatch(uiHandleLoading());
};

// Form Signature
export const createSignatureAction = (
  payload: ISignatureModel,
  history: any,
  form: string,
) => async (dispatch: any) => {
  if (!payload.body)
    return message.error('Please create a template in the editor');
  dispatch(uiHandleOptionalLoading(true));
  const { err }: any = await createSignature(payload);
  if (err) {
    dispatch(uiHandleOptionalLoading(false));
    return message.error(`${err?.message || err?.errors || err?.error}`);
  } else {
    message.success('Created Template!');
    dispatch(handleResetForm(form));
    return history.push('/signatureList');
  }
};

export const updateSignatureAction = (
  payload: ISignatureModel,
  history: any,
  form: string,
) => async (dispatch: any) => {
  if (!payload.body)
    return message.error('Please create a template in the editor');
  dispatch(uiHandleOptionalLoading(true));
  const { err }: any = await editSignature(payload, payload.id);
  if (err) {
    dispatch(uiHandleOptionalLoading(false));
    return message.error(`${err?.message || err?.errors || err?.error}`);
  } else {
    message.success('Updated Signature!');
    dispatch(handleResetForm(form));
    return history.push('/signatureList');
  }
};

export const handleFormSignature = (payload: any) => (
  dispatch: any,
  getState: any,
) => {
  const { signature } = getState('forms');
  dispatch({
    type: type.formHandleSignature,
    payload: { ...signature, ...payload },
  });
};

export const handleEditFormSignature = ({ id }: any) => async (
  dispatch: any,
) => {
  dispatch(uiHandleLoading());
  const edit = { id, editing: true };
  const { data, err } = await getByIdSignature(id);
  const entities = await getByIdEntities({ id, page: 1, limit: 0 });
  data.entities = entities.data.records;

  if (err) message.error(`${err?.message || err?.errors || err?.error}`);
  else dispatch(handleFormSignature({ ...data, edit }));
  dispatch(uiHandleLoading());
};

export const addEntitySignature = (payload: any) => (dispatch: any) =>
  dispatch({
    type: type.formAddEntity,
    payload,
  });

export const removeEntitySignature = (payload: string) => (dispatch: any) =>
  dispatch({
    type: type.formRemoveEntity,
    payload,
  });

// Profile page
export const handleFormProfile = (payload: any) => (
  dispatch: any,
  getState: any,
) => {
  const { forms } = getState();
  dispatch({
    type: type.formHandleProfile,
    payload: { ...forms.profile, ...payload },
  });
};

export const handleEditFormProfile = (payload: any) => async (
  dispatch: any,
) => {
  dispatch(uiHandleLoading());
  const { data, err } = await getByIdProfile(payload);
  console.log(err);
  if (err) message.error(`${err?.message || err?.errors || err?.error}`);
  else dispatch(handleFormProfile({ ...data, editing: true }));
  dispatch(uiHandleLoading());
};
