import { Form, Typography } from 'antd';
import useQuery from 'hooks/useQuery';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import useStateRedux from 'hooks/useStateRedux';
import React, { useCallback, useEffect, useState } from 'react';
import Loading from 'components/loading/loading';
import ListDash from 'components/listDash/listDash';
import CurrentCreateSignatureView from './components';
import { listMenuSignature } from 'helpers/listDashboard';
import { Actions } from 'components/actionsForm/actionsForm';
import { formSignature, formTemplate } from 'utils/formConstant';
import {
  createSignatureAction,
  handleEditFormSignature,
  handleFormSignature,
  handleResetForm,
  updateSignatureAction,
} from 'redux/actions/forms.action';
import UpgradePlanModal from './components/upgradePlanModal';

const { Title } = Typography;

const CreateSignaturePage = () => {
  const query = useQuery();
  const id = query.get('id');
  const history = useHistory();
  const dispatch = useDispatch();
  const { forms, ui }: any = useStateRedux();
  const [state, setState] = useState({
    list: listMenuSignature,
    active: 'information',
  });

  const [isModalVisible, setIsModalVisible] = useState(false);

  const { signature } = forms;

  // click menu in dash card
  const onClick = useCallback(
    (id: any) =>
      setState((prev) => {
        const list = prev.list.map((i) =>
          i.id === id ? { ...i, active: true } : { ...i, active: false },
        );
        return { ...prev, list, active: id };
      }),
    [setState],
  );

  // Save or update signature
  const onFinish = useCallback(() => {
    dispatch(handleFormSignature({ save: true }));
    dispatch(
      signature.edit.editing
        ? updateSignatureAction(signature, history, formTemplate)
        : createSignatureAction(signature, history, formTemplate),
    );
  }, [dispatch, signature]);

  useEffect(() => {
    !!id && dispatch(handleEditFormSignature({ id }));
  }, [dispatch, id]);

  // Important: Clear editor form  the close page
  useEffect(() => {
    return function cleanup() {
      dispatch(handleResetForm(formSignature));
    };
  }, []);

  if (ui.loading) return <Loading />;

  // form property must be equal to the name of the reducer to access it {template, signature, etc}
  return (
    <div
      className="animate__animated animate__fadeIn mb-5"
      style={{ minHeight: '75vh', position: 'relative' }}
    >
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        {signature.edit.editing ? 'Edit Signature' : 'Create Signature'}
      </Title>
      {/*Action Buttons*/}
      <div className="view-desktop">
        <Actions form={formSignature} setIsModalVisible={setIsModalVisible} />
      </div>

      {/*Menu*/}
      <ListDash
        state={state.list}
        onClick={onClick}
        title="Choose step for complete"
      />

      {/*Launch event finish*/}
      <Form onFinish={onFinish} name={formSignature} />

      {/*Current view*/}
      <CurrentCreateSignatureView
        active={state.active}
        isEditing={signature.edit.editing}
      />
      <UpgradePlanModal
        isModalVisible={isModalVisible}
        setIsModalVisible={setIsModalVisible}
      />
    </div>
  );
};

export default CreateSignaturePage;
