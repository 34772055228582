import { Table } from 'antd';
import React, { useMemo, useState } from 'react';
import { useFetchExecutionList } from 'api/executions/executions.api';
import { ColumnsType } from 'antd/lib/table/Table';
import { IExecution } from 'models/api/signature.model';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const ExecutionListPage = () => {
  const history = useHistory();
  const location = useLocation();
  const [page, setPage] = useState(1);
  const { signatureId } = useParams<any>();

  const { data, loading } = useFetchExecutionList({
    page,
    signatureId,
  });

  const columns = useMemo(
    (): ColumnsType<IExecution> => [
      {
        title: 'ID',
        dataIndex: 'id',
      },
      {
        title: 'STATUS',
        dataIndex: 'status',
      },
      {
        title: 'TIME',
        dataIndex: 'created_date',
        render: (value: number) => moment(value).format('LLL'),
      },
    ],
    [data],
  );

  const handlePagination = (pagination: any) => setPage(pagination.current);

  return (
    <div style={{ overflowX: 'auto' }} className="beauty-scroll">
      <Table
        className="mt-1"
        loading={loading}
        onChange={handlePagination}
        rowKey={(row) => row.id}
        dataSource={data.items.sort((a, b) => b.created_date - a.created_date)}
        columns={columns}
        onRow={(item) => ({
          onClick: () => history.push(`${location.pathname}/${item.id}`),
        })}
        pagination={{
          pageSize: 25,
          current: page,
          showSizeChanger: false,
          total: data.total_items,
        }}
      />
    </div>
  );
};

export default ExecutionListPage;
