import { Card, Typography } from 'antd';

import GeneralForm from './components/general';

const { Title } = Typography;

const SettingsPage = () => {
  return (
    <div className="animate__animated animate__fadeIn">
      <Title level={1} style={{ marginBlockEnd: 0 }}>
        Settings
      </Title>
      <Typography.Text className="text-secondary">
        <b>Choose step for config:</b>
      </Typography.Text>

      {/* <div className="view-desktop">
        <ActionsSettings />
      </div> */}

      <Card className="settings-container">
        <div className="settings-content">
          <div className="settings-view">
            <GeneralForm />
          </div>
        </div>
      </Card>

      {/*View mobile*/}
      {/* <div className="view-mobile">
        <ActionsSettings />
      </div> */}
    </div>
  );
};

export default SettingsPage;
