import CKEditor from 'ckeditor4-react';
import { useRef, useState } from 'react';
import { contactFields, personalFields } from '../../utils/utils';
import configEditor from '../../services/ckEditorToolbar';
import { insertAttr } from '../../services/plugin/insertName';
import {
  Button,
  Modal,
  Select,
  Typography,
  message,
  Dropdown,
  Menu,
} from 'antd';
import CustomDropdown from 'components/dropdown/CustomDropdown';
// import PreviewTemplate from './PreviewTemplate';
// import { formSignature } from 'utils/formConstant';
// import useStateRedux from 'hooks/useStateRedux';

const { Option } = Select;

const invalidPatterns = [
  { pattern: '}}', message: 'Debe eliminar la llave extra "}}".' },
  { pattern: '{{', message: 'Debe eliminar la llave extra "{{".' },
  { pattern: ']}', message: 'Debe eliminar la combinación inválida "]}".' },
  { pattern: '}]', message: 'Debe eliminar la combinación inválida "}]".' },
  { pattern: '[{', message: 'Debe eliminar la combinación inválida "[{".' },
  { pattern: '{[', message: 'Debe eliminar la combinación inválida "{[".' },
  { pattern: '{$', message: 'Debe eliminar la combinación inválida "{$".' },
];

CKEditor.editorUrl = `${process.env.PUBLIC_URL}/ckeditor/ckeditor.js`;
const initFunction = () => {};

const EditorComponent = ({
  data = '',
  onChangeEditor = initFunction,
  title = 'Design signature',
}) => {
  // const { forms } = useStateRedux();

  const ref = useRef(null);

  const clickAttr = (name) =>
    !!ref.current?.editor && insertAttr(ref.current?.editor, name);

  const showData = () => {
    const editorData = ref.current?.editor?.getData();

    invalidPatterns.forEach(({ pattern, message: errorMessage }) => {
      if (editorData.includes(pattern)) {
        message.destroy();

        message.warning(errorMessage);
      }
    });

    !!onChangeEditor && onChangeEditor(editorData);
  };

  return (
    <>
      <div className="mb-1 ant-form-item-label">
        <label
          htmlFor="template_summary"
          className="ant-form-item-required"
          title="Description/Summary"
        >
          {title}
        </label>
      </div>

      <div className="custom-plugin-editor-wrapper">
        <div className="custom-plugin-editor-dropdown">
          <CustomActions clickAttr={clickAttr} />
        </div>

        <div className="custom-plugin-editor-content">
          <div className="custom-plugin-editor-editor">
            <CKEditor
              ref={ref}
              data={data}
              onChange={showData}
              config={configEditor}
            />
          </div>
          {/* <div className="custom-plugin-preview">
            <PreviewTemplate body={forms[formSignature].body} />
          </div> */}
        </div>
      </div>
    </>
  );
};

const CustomActions = ({ clickAttr }) => {
  const [visible, setVisible] = useState(false);
  const handleVisible = () => setVisible(!visible);
  const onClickModal = (insert) => clickAttr(insert);
  return (
    <>
      <ModalIf
        visible={visible}
        handleVisible={handleVisible}
        onClick={onClickModal}
      />

      <Dropdown
        overlay={
          <Menu>
            <Menu.Item onClick={() => clickAttr('#{if} #{/if}')}>
              Manual IF
            </Menu.Item>
            <Menu.Item onClick={handleVisible}>Helper IF</Menu.Item>
          </Menu>
        }
        placement="bottomLeft"
      >
        <Button type="dashed">Insert {'>'} Conditional</Button>
      </Dropdown>

      <CustomDropdown
        title="Insert > Personal Fields"
        items={personalFields}
        clickAttr={clickAttr}
      />

      <CustomDropdown
        title="Insert > Contact Info"
        items={contactFields}
        clickAttr={clickAttr}
      />
    </>
  );
};

const ModalIf = ({ visible, handleVisible, onClick }) => {
  const [state, setState] = useState([]);
  const key = state === [] ? '' : state;

  const onChange = (val) => setState(val);
  const afterClose = () => setState([]);
  const onFinish = () => {
    onClick(`#{if ${key}} ${'${' + key + '}'} #{/if}`);
    handleVisible();
  };

  return (
    <Modal
      footer={null}
      visible={visible}
      afterClose={afterClose}
      onCancel={handleVisible}
      title="Create a condition in the editor"
    >
      <div className="mb-2">
        <Select
          showSearch
          size="large"
          value={state}
          onChange={onChange}
          style={{ width: '100%' }}
          optionFilterProp="children"
          placeholder="Select a person"
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {personalFields.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.title}
            </Option>
          ))}
          {contactFields.map((i) => (
            <Option key={i.id} value={i.id}>
              {i.title}
            </Option>
          ))}
        </Select>

        {/*Preview if*/}
        <div className="mt-1">
          <Typography.Title level={5}>Preview</Typography.Title>
          <Typography.Text code>{`#{if ${key}} ${
            '${' + key + '}'
          } #{/if}`}</Typography.Text>
        </div>
      </div>
      <div style={{ textAlign: 'right' }}>
        <Button onClick={handleVisible} type="default" className="mr-1">
          Cancel
        </Button>
        <Button onClick={onFinish} type="primary">
          Insert condition
        </Button>
      </div>
    </Modal>
  );
};

export default EditorComponent;
