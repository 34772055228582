import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { FormInstance } from 'antd/es/form/Form';
import useStateRedux from 'hooks/useStateRedux';
import { Col, Form, Input, Row, Select, Typography } from 'antd';
import { handleFormProfile } from 'redux/actions/forms.action';
import { useFetchEntityList } from 'api/entities/entities.api';

const { Title } = Typography;

const GeneralFrom = ({ form }: IProps) => {
  const dispatch = useDispatch();
  const { profile }: any = useStateRedux('forms');
  const [managers, setManagers] = useState<any>([]);
  const [searchTerm, setSearchTerm] = useState('');

  const { data, loading } = useFetchEntityList({
    search: searchTerm,
    available: true,
    type: 'PERSON',
  });

  useEffect(() => {
    if (data) {
      setManagers(data.items);
    }
  }, [data]);

  const formChange = (values: any) => {
    if (values?.organizations || values?.relations) {
      const updatedValues = {
        organizations: [
          {
            ...profile.organizations[0],
            ...values.organizations,
          },
        ],
        relations: [
          { ...profile.relations[0], ...values.relations, type: 'manager' },
        ],
      };
      dispatch(handleFormProfile(updatedValues));
    }
  };

  useEffect(() => {
    const newProfile = { ...profile };

    newProfile.organizations = newProfile.organizations[0];
    newProfile.relations = newProfile.relations[0];
    newProfile.name = newProfile.name;

    form.setFieldsValue(newProfile);
  }, [profile, form]);

  const handleSearch = (value: string) => {
    setSearchTerm(value);
  };

  return (
    <div>
      <Form
        form={form}
        className="mt-3"
        layout="vertical"
        onValuesChange={formChange}
      >
        <Row gutter={[20, 20]}>
          <Col sm={24} md={12}>
            <Title level={3}>ORGANIZATION</Title>
            <Form.Item
              name={['organizations', 'name']}
              label="Company"
              rules={[
                {
                  required: true,
                  message: 'Company is required',
                },
              ]}
            >
              <Input placeholder="Aeegle Inc" size="large" />
            </Form.Item>

            <Form.Item name={['relations', 'value']} label="Manager">
              <Select
                placeholder="Select a manager"
                size="large"
                showSearch
                filterOption={false}
                onSearch={handleSearch}
                loading={loading}
                options={managers.map((manager: any) => ({
                  label: manager.email,
                  value: manager.email,
                }))}
              />
            </Form.Item>

            <Form.Item name={['organizations', 'title']} label="Title">
              <Input placeholder="Cloud Solutions Specialist" size="large" />
            </Form.Item>
            <Form.Item
              name={['organizations', 'department']}
              label="Department"
            >
              <Input placeholder="Consulting & Development" size="large" />
            </Form.Item>
            <Form.Item name={['organizations', 'location']} label="Location">
              <Input
                placeholder="5ta Calle Pnte #3716, Colonia Escalón, San Salvador"
                size="large"
              />
            </Form.Item>
          </Col>
          <Col sm={24} md={12}>
            <Title level={3}>OVERVIEW</Title>
            <Form.Item name={['organizations', 'description']} label="Summary">
              <Input.TextArea
                placeholder="This is my tagline"
                size="large"
                rows={5}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

interface IProps {
  form: FormInstance<any>;
}

export default GeneralFrom;
