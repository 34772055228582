import React from 'react';
import { Button, notification, Typography } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import useStateRedux from 'hooks/useStateRedux';
import { syncPeople } from 'api/person/person.api';
import AdminForm from './adminProfile';
import DomainList from './domainList';

const { Title, Text } = Typography;

const GeneralForm = () => {
  const { customer_id }: any = useStateRedux('auth');

  const syncAll = () => {
    notification.info({
      message: 'User synchronization',
      description: 'User synchronization has started',
    });
    syncPeople(customer_id, ['users', 'groups']);
  };
  return (
    <div className="settings__wrapper animate__animated animate__fadeIn">
      <AdminForm />
      {/* General */}
      <DomainList />
      <div className="card__list-wrapper card__create-form">
        <div>
          <Title level={5}>Manual synchronization</Title>
          <Text>
            This option will synchronize to Zumpul all the updates made to the
            users' profiles and groups in G Suite
          </Text>
          <div className="mt-1">
            <Button
              icon={<SyncOutlined />}
              type="primary"
              size="large"
              onClick={syncAll}
            >
              Sync
            </Button>
          </div>
        </div>
      </div>
      {/* Massive */}
      {/* <div
        className="mt-3 card__list-wrapper card__create-form"
        style={{ textAlign: 'left' }}
      >
        <div>
          <Title level={5}>Massive profiles update</Title>

          <div className="mt-1">
            <Timeline>
              <Timeline.Item>
                Make a copy of Profile organization Template SpreadSheet.{' '}
                <a
                  href={
                    'https://docs.google.com/spreadsheet/ccc?key=1aSF0HtQIftCD4FM4bpym-VeLWSknwKFqlDtyVYUpQ7g&newcopy=true'
                  }
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Click Here
                </a>
              </Timeline.Item>
              <Timeline.Item>Fill in the copy created.</Timeline.Item>
              <Timeline.Item>
                <div>Select your own spreadsheet from google drive.</div>
                <Button
                  size="large"
                  type="default"
                  icon={<GoogleOutlined />}
                  className="btn-secondary mt-1"
                >
                  Open Google Drive
                </Button>
              </Timeline.Item>
              <Timeline.Item>Click in update button</Timeline.Item>
            </Timeline>
          </div>
        </div>
      </div>
      <Button
        className="mt-2 mb-4"
        type="primary"
        size="large"
        icon={<CloudUploadOutlined />}
      >
        Update
      </Button> */}
    </div>
  );
};

export default GeneralForm;
