import EmptyList from 'components/empty/empty';
import Loading from 'components/loading/loading';
import { CardListPeople } from '../card/cardListPeople';
import { columnsSelect } from 'helpers/listDashboard';
import { useFetchEntityList } from 'api/entities/entities.api';
import { Input, Table, Typography } from 'antd';
import React, { memo, ReactNode, useEffect, useState } from 'react';
import { hideEntitiesOfData } from '../../utils/transform';

const { Text } = Typography;

const SelectPeople = memo((props: IProps) => {
  const [page, setPage] = useState(1);
  const [inputSearch, setInputSearch] = useState('');
  const {
    type,
    tooltip,
    entities,
    addEntity,
    removeEntity,
    searchValue = '',
    notSearch = false,
    extra: ExtraComponent,
    loading: loadingList,
    onSearch = (val) => console.log(val),
  } = props;
  const { data, loading } = useFetchEntityList({
    page,
    type,
    search: searchValue,
    limit: 30,
  });

  useEffect(() => {
    if (!!searchValue) {
      setPage(1);
      setInputSearch(searchValue);
    }
  }, [searchValue]);

  const handlePagination = (pagination: any) => setPage(pagination.current);

  return (
    <>
      {!notSearch && (
        <div className="card__list-wrapper card__create-form flex">
          <Input.Search
            allowClear
            size="large"
            value={inputSearch}
            onSearch={onSearch}
            style={{ width: 500, maxWidth: '100%' }}
            placeholder="Search by name or email address"
            onChange={(event) => setInputSearch(event.target.value)}
          />
          {!!ExtraComponent && (
            <div className="options">
              <ExtraComponent value={inputSearch} />
            </div>
          )}
        </div>
      )}
      <div className="mt-4 signatures__table-wrapper">
        <div className="col-responsive mr-responsive">
          <Text>
            <b>
              {type === 'PERSON'
                ? 'Select persons:'
                : 'Select persons or groups:'}
            </b>
          </Text>
          <div style={{ overflowX: 'auto' }} className="beauty-scroll">
            <Table
              className="mt-1"
              loading={loading}
              onChange={handlePagination}
              dataSource={hideEntitiesOfData(data.items, entities)}
              columns={columnsSelect(addEntity, false, tooltip)}
              pagination={{
                pageSize: 30,
                current: page,
                showSizeChanger: false,
                total: data.total_items,
                showTotal: (total: number, range: [number, number]) =>
                  `View ${range[0]} - ${range[1]} of ${
                    total === 0 ? `0` : `${total} items`
                  }`,
              }}
            />
          </div>
        </div>
        <div className="col-responsive">
          <Text>
            <b>
              {type === 'PERSON'
                ? 'Chosen persons:'
                : 'Chosen persons or groups:'}
            </b>
          </Text>
          <div className="mt-1">
            {loadingList ? (
              <Loading />
            ) : !!entities.length ? (
              entities.map((u: any) => (
                <CardListPeople
                  {...u}
                  key={u.id}
                  footer={true}
                  onClick={() => removeEntity(u)}
                />
              ))
            ) : (
              <EmptyList />
            )}
          </div>
        </div>
      </div>
    </>
  );
});

interface IProps {
  type?: 'PERSON' | 'GROUP' | '';
  entities: any[];
  loading?: boolean;
  tooltip?: ReactNode;
  notSearch?: boolean;
  searchValue?: string;
  onSearch?: (val: string) => void;
  addEntity: (record: any) => void;
  removeEntity: (record: any) => void;
  extra?: React.ReactNode | any;
}

export default SelectPeople;
