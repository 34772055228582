import { Button, message, Modal, Typography } from 'antd';
import { deleteCustomer } from 'api/authAndCustomer/actionsCustomer.api';
import logout from 'config/google/logout';
import { useDispatch } from 'react-redux';
import { useGoogleApi } from 'react-gapi';
import { scopes } from 'config/google/scopes';
import { useState } from 'react';
import useStateRedux from 'hooks/useStateRedux';

const UpgradeSub = () => {
  // @ts-ignore
  const gapi = useGoogleApi({ scopes });
  const auth = gapi?.auth2?.getAuthInstance();
  const dispatch = useDispatch();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { admin_full_name, admin_email, user_is_admin }: any = useStateRedux(
    'auth',
  );
  const handleModal = () => {
    setIsModalVisible(!isModalVisible);
  };

  const uninstall = () => {
    const key = 'uninstall';
    message.loading({ content: 'Deleting...', key });
    deleteCustomer()
      .then(async (e) => {
        if (e.error) {
          message.error(e.error?.message ?? e.error);
        } else {
          message.success({
            content: 'customer deleted!!',
            duration: 3,
            key,
            onClose: async () => await logout(dispatch, auth),
          });
        }
      })
      .catch(() => {
        message.error('error!! deleting customer');
      });
  };

  return (
    <>
      {user_is_admin && (
        <div className="card__create-form card__list-wrapper mb-5">
          <div style={{ width: '100%' }}>
            <div className="mb-4">
              <Typography.Title style={{ fontWeight: 400 }} level={2}>
                Uninstall Zumpul and Delete Account
              </Typography.Title>
              <Typography.Text>
                By uninstalling Zumpul, you and all administrators associated
                with this account will permanently lose access to all linked
                services, and all your data will be irreversibly deleted from
                our system.
              </Typography.Text>
            </div>
            <div style={{ marginTop: '10px', display: 'grid' }}>
              <Typography.Text>Account name and organization:</Typography.Text>
              <Typography.Text>{`${admin_full_name} | ${admin_email}`}</Typography.Text>
              <Typography.Text style={{ marginTop: '10px' }}>
                What this means for you:
              </Typography.Text>

              <ul>
                <li>
                  This account, along with all administrator accounts, will
                  permanently lose access to Zumpul.
                </li>
                <li>
                  All created signatures and templates will be permanently
                  deleted and cannot be recovered.
                </li>
                <li>
                  There is no backup in Zumpul. If you reinstall it in the
                  future, you will have to start from scratch.
                </li>
                <li>
                  The only data that will remain intact are the fields updated
                  in Google Admin Console via Zumpul.
                </li>
              </ul>
              <Typography.Text>
                Once this action is completed, it cannot be undone. Please
                ensure you are ready to proceed.
              </Typography.Text>
            </div>
            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button type="default" onClick={handleModal}>
                Delete
              </Button>
            </div>
          </div>
          <Modal
            bodyStyle={{ paddingBottom: 35 }}
            title="Uninstall Account"
            visible={isModalVisible}
            onCancel={handleModal}
            // onOk={() => {
            //   uninstall();
            //   handleModal();
            // }}
            footer={null}
          >
            <h4>Are you sure your want to terminate your account?</h4>
            <div style={{ marginTop: '10px', display: 'grid' }}>
              <Typography.Text style={{ marginTop: '10px' }}>
                Step 1:
              </Typography.Text>
              <ul>
                <li>
                  Uninstall Zumpul at the following URL:{' '}
                  <a
                    href="https://admin.google.com/ac/apps/gmail/marketplace/appdetails/394696046694"
                    target="_blank"
                  >
                    Uninstall Zumpul
                  </a>
                </li>
              </ul>
              <Typography.Text style={{ marginTop: '10px' }}>
                Step 2:
              </Typography.Text>
              <ul>
                <li>
                  Once the previous step is done, press the{' '}
                  <Button onClick={() => uninstall()}>Ok</Button>
                </li>
              </ul>
            </div>
          </Modal>
        </div>
      )}
    </>
  );
};
export default UpgradeSub;
