import { Button, Modal, Typography } from 'antd';

const UpgradePlanModal = ({
  isModalVisible,
  setIsModalVisible,
}: UpgradePlanModalProps) => {
  return (
    <Modal
      title="Unlock More Possibilities!"
      visible={isModalVisible}
      onCancel={() => setIsModalVisible(false)}
      footer={null}
    >
      <Typography.Paragraph style={{ fontSize: 16, textAlign: 'center' }}>
        To apply a form, you need an active Pro or Enterprise subscription. Want
        to create more than 10 signatures? Upgrade to Enterprise and enjoy
        unlimited flexibility!
      </Typography.Paragraph>
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button
          type="primary"
          size="large"
          style={{
            height: 44,
            padding: '0 24px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          onClick={() =>
            (window.location.href = `${process.env.REACT_APP_AUTH_ACCOUNT_HOST}/checkout`)
          }
        >
          Upgrade Now!
        </Button>
      </div>

      <div style={{ display: 'flex', justifyContent: 'center', marginTop: 20 }}>
        <Button type="default" onClick={() => setIsModalVisible(false)}>
          Maybe Later
        </Button>
      </div>
    </Modal>
  );
};

interface UpgradePlanModalProps {
  isModalVisible: boolean;
  setIsModalVisible: any;
}

export default UpgradePlanModal;
