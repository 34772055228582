import React, { useEffect } from 'react';
import Col from 'antd/es/grid/col';
import { Form, Input, Row, Tag } from 'antd';
import useStateRedux from 'hooks/useStateRedux';
import { useForm } from 'antd/es/form/Form';
import { CheckCircleOutlined } from '@ant-design/icons';

const AdminForm = () => {
  const [form] = useForm();
  const auth: any = useStateRedux('auth');

  useEffect(() => {
    form.setFieldsValue(auth);
  }, [form]);

  return (
    <div className="settings__wrapper">
      <div className="settings__head-user">
        <div>
          <Tag
            icon={<CheckCircleOutlined />}
            color="red"
            style={{ fontSize: '16px', padding: '8px 16px' }}
          >
            Installed by
          </Tag>
        </div>
      </div>
      <Form form={form} className="mt-2" layout="vertical" name="form-profile">
        <Row gutter={20}>
          <Col xs={24} md={12}>
            <Form.Item name="admin_full_name" label="Name:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="admin_email" label="Email:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="job_title" label="Role:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="summary" label="Company:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="number_of_employees" label="Number of Employees:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={12}>
            <Form.Item name="type_gaps" label="Type of G Suite:">
              <Input disabled size="large" />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default AdminForm;
